import { BREAK_ } from 'constants'
import { BREAK } from 'constants'
import styled from 'styled-components/macro'

export const Main = styled.div`
  // grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  display: flex;
  gap: 8px;
  /* border:1px solid black; */
  align-items: center;
  justify-content: center;
  background:transparent;
  white-space: nowrap;
  overflow: hidden;
  textOverflow: ellipsis;
  flex-wrap: wrap;
`

export const Item = styled.div`
  // width: 100%;
  width: 450px;
  height: 461px;
  cursor: pointer;
  padding: 0px 1px 0 1px;
  /* border:1px solid black; */
  border-radius: 6px;
  border-top: 8px solid ${({ color }) => color};
  background-color: ${({ color }) => (() => color + '22')()};
  :hover { background-color: ${({ color }) => (() => color + '33')()}; }
  ${BREAK_.md_dn} {
    height: ${({atUnit}) => atUnit ? "386px" : "423px"};
    overflow: hidden;
    width: ${({atUnit}) => atUnit ? "300px" : "361px"};
  }
`