import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1444,
  maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none"
};

const FloorplansModalButton = ({ unit }) => {
  const floorplanUrls = unit?.data?.Gallery?.Floorplans ?? [];
  const [open, setOpen] = React.useState(false);
  const [swiper, setSwiper] = React.useState(null); // To hold the Swiper instance
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(0); // Reset index when modal is closed
  };

  // Function to handle next slide
  const nextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  // Function to handle previous slide
  const prevSlide = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  // Function to update current index on slide change
  const handleSlideChange = () => {
    if (swiper !== null) {
      setCurrentIndex(swiper.activeIndex);
    }
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          height: '45px',
          flexDirection: "row",
          gap: '5px',
          backgroundColor: `${clr?.buttons_text}bf`,
          borderRadius: "6px",
          color: "#000000",
          py: "8px",
          px: "16px",
          lineHeight: 1,
          fontWeight: 400,
          fontSize: 14,
          "&:hover": {
            backgroundColor: `${clr?.buttons_text}`,
            color: "#000000",
          },
        }}
      >
        <Icon icon="floorplan" style={{ marginRight: "8px" }} />
        <Box sx={{ fontWeight: 'bold', letterSpacing: '1px' }}>
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "plan"
            )[0]?.Label
          }
        </Box>
      </Button>
      <Modal open={open} onClose={handleClose} sx={{
        " .MuiBackdrop-root": {
          backgroundColor: `${clr.primary}bf`
        },
      }}>
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              zIndex: "1301",
              position: "absolute",
              right: 0,
              top: -40,
              color: "#fff",
            }}
          >
            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          {floorplanUrls.length > 1 && (
            <>
              <IconButton
                onClick={prevSlide}
                sx={{
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                }}
                disabled={currentIndex === 0}
              >
                <Icon icon="arrow-left" fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={nextSlide}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  color: "#000000",
                  backgroundColor: "rgba(255,255,255,0.8)",
                }}
                disabled={currentIndex === floorplanUrls.length - 1}
              >
                <Icon icon="arrow-right" fontSize="inherit" />
              </IconButton>
            </>
          )}
          <Swiper
            onSwiper={setSwiper}
            onSlideChange={handleSlideChange}
            loop={false} // Disable looping if not needed
          >
            {floorplanUrls.map((floorplan, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="img"
                  src={floorplan?.Url}
                  alt={`Floorplan ${index + 1}`}
                  sx={{
                    display: "block",
                    width: "100%",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Modal>
    </div>
  );
};

FloorplansModalButton.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export { FloorplansModalButton };
