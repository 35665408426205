import { useAtom, getFocus, useGet, useSet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import {
  active_project,
  current_language,
  isMulti_project_active,
  iso_free,
  iso_isrotating,
  vis_filter,
  vis_desktop_nav,
  turn_building,
} from "state/store.global";
import { close_drawer, toggle_drawer } from "state/actions";

import { Icon } from "assets/Icons_";

import { FlexBox, FullWidth, Section } from "./DualMenu.style";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";
import { Filter } from "components/TopMenu/TopMenu.style";
import { Mode360 } from "components/IsometryRotation/360";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { Controls } from "components/Isometry/Isometry.Controls";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import { useSet } from "react-use";
import { Dropdown } from "components/Dropdown/Dropdown";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Box } from "@mui/material";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { OverviewDualMenu } from "./OverviewDualMenu";
import { IsometryDualMenu } from "./IsometryDualMenu";
import { NewIsometryDualMenu } from "./NewIsometryDualMenu";

// import { options } from './../Dropdown2/Dropdown2.Data';

export const DualMenu = ({ left, right, nav }) => {

  const isDesktop = useBreak("md_up");
  const navigate = useNavigate();
  const location1 = useLocation().pathname.split("/")[1];
  const names = getFocus(_bootstrap, "projectnames");
  const currentLanguage = useAtom(current_language)[0];
  const percent = getIsoData("percent");

  const here = useLocation().pathname;
  const type = getFocus(_bootstrap, "type");
  const rmode = useAtom(iso_free)[0];
  const isFilter = useAtom(vis_filter)[0];
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const [, setShowFilter] = useAtom(vis_filter);
  const closeDrawer = useAtom(close_drawer)[1];
  const setMultiActive = useAtom(isMulti_project_active)[1];
  const toggleDrawer = useAtom(toggle_drawer)[1];
  const projectType = getFocus(_bootstrap, "type");
  const atModel = here.includes("isometry");
  const [turnBuilding, setIsTurnBuilding] = useAtom(turn_building);

  const isMobile = useBreak("md_dn");

  const [state, setState] = useState(false);
  const [showProperty, setShowProperty] = useState(false);
  const [showSelectFunction, setShowSelectFunction] = useState(false);

  const config = getIsoData("config");
  const frame = getIsoData("activeframe");
  const frames = getIsoData("frames");
  const index = getIsoData("snapindex");
  const setIndex = setIsoData("snapindex");
  const setFrame = setIsoData("activeframe");
  const multiActive = useAtom(isMulti_project_active)[0];

  const showFooter =  [
    "isometry",
    "list"
  ].some((i) => here.includes(i));

  const hideFilterMultiActiveTrue = [
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));

  const hideFilterMultiActiveFalse = [
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));

  const filterShouldShow = !multiActive
    ? !hideFilterMultiActiveTrue
    : !hideFilterMultiActiveFalse;

  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const filter = {
    size: "lg",
    icon: "filters",
    color: !isDesktop || pin > 0 || showButton ? clr?.primary_text : clr?.primary_text,
  };

  const clickFilter = () => {
    toggleDrawer("filter");
    setShowMenu(false);
    setShowProperty(false);
  };
  const {
    i18n: { language },
  } = useTranslation();

  const clickList = () => {
    setShowFilter(false);
    setShowMenu(false);
    if (pin > 0) setMultiActive(true);
    else setMultiActive(false);
    navigate(type == "mono" ? `/${language}/list` : `${language}/list`);
  };

  const clickModel = () => {
    closeDrawer("detail");
    setShowFilter(false);
    setShowMenu(false);
    navigate(
      type == "mono"
        ? `/${language}/isometry`
        : `/${language + names[currentLanguage][pin].path}`
    );
  };
  const openSelectFunction = () => {
    setShowSelectFunction(true);
  };
  const list = {
    size: "lg",
    icon: "list",
    color: here.includes("list") || pin > 0 || isMobile ? "#000" : "#000",
  };
  const model = {
    size: type === 25,
    icon: type === "model",
    color: clr?.primary_text
  };

  const rotatemode = useGet(iso_free);
  const setRotatemode = useSet(iso_free);
  const isrotate = useGet(iso_isrotating);
  const setIsrotate = useSet(iso_isrotating);

  const click1 = () => {
    setIsrotate(false);
    setIsTurnBuilding(true);
  };

  const click2 = () => {
    setRotatemode(true);
    setIsrotate(true);
    setIsTurnBuilding(true);
  };

  useEffect(() => {
    if (rotatemode) {
      setShowFilter(false);
      setShowMenu(false);
      closeDrawer("detail");
    }
  }, [rotatemode]);

  const props = {atModel, left, right, nav, pin, openSelectFunction, showSelectFunction, setShowSelectFunction, clickList, list, buttonsLabel, showProperty, setShowProperty, toggleDrawer, config, rotatemode, click2, turnBuilding, isrotate, click1, model, clickModel, filterShouldShow, clickFilter, filter, state, setState, closeDrawer, setShowFilter, setShowMenu }
  return (
    <>
    {/* {console.log(type==="poly", pin === 0 ,here.includes("isometry") ,!isMobile  ,"hiii?")} */}
    {
      
      type==="poly" && pin === 0 && here.includes("isometry") && !isMobile ? <OverviewDualMenu {...props} /> : showFooter ? <IsometryDualMenu {...props} /> : null
        //  type==="poly" && pin === 0 && here.includes("isometry") && !isMobile ? <OverviewDualMenu {...props} />  :  type==="poly"  && pin===1 && here.includes("isometry") && !isMobile ? <NewIsometryDualMenu {...props} />: showFooter ? <IsometryDualMenu {...props} /> :null
    } 
    </>
  );
};
