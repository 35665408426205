import { Icon } from 'assets/Icons_';
import { BREAK_ } from 'constants';
import { Link, useLocation } from 'react-router-dom';
import { getFocus, useAtom, useGet } from 'state/jotai';
import { active_project, params_query } from 'state/store.global';
import { _project } from 'state/store.projects';
import styled from 'styled-components';

export const BackButton = ({ color, style, text }) => {
  const location = useLocation()
  const params = useGet(params_query);
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  return (
    <Navigate style={style} to={location.key !== 'default' ? -1 : `/${params.length ? params : ''}`}>
      <Icon
        icon="back"
        isFaIcon={true}
        size={"18"}
        color={color}
      />
      <Text className="paragraph1-regular" clr={clr} color={color} >
        {
          buttonsLabel?.filter(
            (btn) => btn.UILabelProperty === "Tilbage"
          )[0]?.Label
        }
      </Text>
    </Navigate>
  );
};


const Navigate = styled(Link)`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  gap: 5px;
  user-select: none;
  color: inherit;
  text-decoration: none;
  width: 100%;
  background: white;
  ${BREAK_.md_up} {
    position: fixed;
    top: 96px;
    left: 48px;
    padding: 0;
  }
  ${BREAK_.md_dn} {
    position: fixed;
    top: 74px;
    left: 0px;
    padding: 16px;
  }
    ${({ style }) => style}
`;
const Text = styled.div`
  white-space: nowrap;
  color: ${({ color }) => color};
`;
