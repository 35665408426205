import { Box, Grid, styled } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function getDataTime(v) {
  const KL = v?.split(" ")[1].slice(0, -3) ?? "";
  const DD = v?.split(" ")[0].split("-")[2] ?? "";
  const MM = v?.split(" ")[0].split("-")[1] ?? "";
  const YY = v?.split(" ")[0].split("-")[0].slice(2, 4) ?? "";
  const HHMM = `${KL}`;
  const DDMMYY = DD && MM && YY ? `${DD}.${MM}.${YY}` : "";
  return [HHMM, DDMMYY];
}

function Statistics({ unit }) {
  const { bookmarked, seen, lastSeen } = unit.getStatistics();
  const [time, date] = getDataTime(lastSeen.LastShownValue);
  const unitId = unit.getId();
  const projectNo                      = unit.getProjectNo();
  const type = unit.getValue("Boligtype");

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <Box
      sx={{
        pt: { xs: 8 },
        pb: { xs: 15, lg: 8 },
        px: { xs: 3, md: 5 },
        // backgroundColor: "secondary.main",
        background: `linear-gradient(180deg, ${clr.primary} 0%, ${clr.primary}7d 100%)`,
        color: "secondary.contrastText",
      }}
    >
      <Box
        sx={{
          mb: { xs: 3, md: 4 },
        }}
       
      >
       <span className="h3"> { buttonsLabel?.filter( btn => btn?.UILabelProperty === 'statistikOverskrift')[0]?.Label } {projectNo}</span>
      </Box>
      <Box
        sx={{
          maxWidth: 500,
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="heart" />
              <div className="paragraph1-light">{bookmarked.bookmarkedLabel}</div>
              <div className="h2">{bookmarked.bookmarkedValue}</div>
            </StyledItem>
          </Grid>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="eye" />
              <div className="paragraph1-light">{seen.seenLabel}</div>
              <div className="h2"s>{seen.seenValue}</div>
            </StyledItem>
          </Grid>
          <Grid item xs={4}>
            <StyledItem>
              <Icon icon="clock" />
              <div className="paragraph1-light" >
                {lastSeen.LastSeenLabel} {date}
              </div>
              <div className="h2">{time}</div>
            </StyledItem>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Statistics.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

const StyledItem = styled((props) => <Box {...props} />)({
  padding: "0 10px",
  maxWidth: "100%",
  textAlign: "center",
  ".MuiSvgIcon-root": {
    fontSize: 40,
    marginBottom: "8px",
  },
  "> div:last-child": {
    // fontSize: 30,
    fontWeight: 700,
    lineHeight: 1,
    marginTop: "8px",
  },
});

export default Statistics;
