import { Label, Main } from './Isometry.Controls.Arrows.style'
import { useState }    from 'react'
import { animate }     from 'framer-motion'
import { Arrow }       from './Isometry.Controls.Arrows.assets'
import { useBreak }    from 'hooks/useBreak'
import { motion }      from 'framer-motion'
import { _drawer, active_project, rotate_by_slider } from 'state/store.global'
import { getFocus, setFocus, useAtom, useGet, useSet } from 'state/jotai'
import { _project } from 'state/store.projects'
import { getIsoData } from 'state/jotai.isometryDataHooks'
import { _bootstrap } from 'state/store.bootstrap'
import { _isometry } from 'state/store.isometry'
import styled from 'styled-components'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useLocation } from 'react-router-dom'

export const Arrows = ({frame,setFrame,config,index,setIndex}) => {
  const type = getFocus(_bootstrap, "type");
  const here = useLocation().pathname;
  const isIsometry = here.includes("isometry")
  const [state, setState] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const isMobile = useBreak('md_dn')
  const SNP = config.IMAGE_SNAPFRAMES 

  const MAX = SNP?.length - 1
  const MIN = 0
  const setRotate_Done = useSet(rotate_by_slider)
  const { pin, pid } = useGet(active_project)
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const percent = getIsoData('percent')

  const pids   = useGet(_bootstrap).projectids
  const allConfig     = getFocus(_isometry, 'config')
  
  const setFrameFocus = setFocus(_isometry, 'activeframe')
  const getFrame = getFocus(_isometry, 'activeframe')

  const drawer   = useAtom(_drawer)[0] 
  const isDetail = drawer.detail

  const click = ( DIR ) => {
    setRotate_Done(false)

    if ( state == 1 ) return
    setState(1)
    const from = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[MIN]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX]
      return SNP?.[index]     
    })()
    const to = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[1]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX-1]
      return SNP?.[index+DIR]
    })()
    const toIndex = (()=>{
      if ( index == MAX && DIR > 0 ) { return 1 }
      if ( index == MIN && DIR < 0 ) { return MAX-1 }
      return index+DIR
    })()
    
    setIndex( toIndex )

    if(pin === 0) {
      animate(from, to, {
        duration: 1.1,
        transition: { ease:'ease-in-out' },
        onUpdate: v => {
          if(+v === SNP[toIndex]) setRotate_Done(true)
          setFrameFocus((f) => (pids.map((p, cnfIndex) => {
            const overviewPid = allConfig?.[0]?.[pids[0]]?.ANGLES;
            if(cnfIndex === 0) {
              return {
                [p]: +v.toFixed(0)
              }
            }else {
              return {
                [p]: overviewPid?.[toIndex]?.[p]?.[p]
              }
            }
          })))
        },
        onComplete:() => setState(0)
      })
    }else {
      animate(from, to, {
        duration: 0.7,
        transition: { ease: "ease-in-out" },
        onUpdate:(v) => {
          if(+v === SNP[toIndex]) setRotate_Done(true)
          setFrame(+v.toFixed(0))
        },
        onComplete:() => setState(0)
      })
    }

    // animate( from, to, {
    //   duration:0.3,
    //   transition: { ease:'linear' },
    //   onUpdate:(v) => {
    //     if(+v === SNP[toIndex]) setRotate_Done(true)
    //     setFrame(+v.toFixed(0))
    //   },
    //   onComplete:() => setState(0)
    // })
  }

  const animation = {
    initial   : { opacity:0 },
    animate   : { opacity:1 },
    exit      : { opacity:0 },
    transition: { duration:0.5, ease:'linear' },
  }
const props = { type, isIsometry, pin, isMobile,isDetail}
  return (
    <Main as={motion.div} {...animation} isDetail={isDetail} pin={pin}>
        {!isMobile && <Tooltip  showTooltip={showTooltip}/>}
      <Section {...props} clr={clr} onClick={() => click(-1)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} isDetail={isDetail}>
        <Arrow {...props} D={'L'} w={ isMobile ? '25' : '25' } fill={ pin==0 && type==="poly" && isIsometry ? isMobile ? clr.primary_text : "#000" : clr?.primary_text }  {...{percent}}/>
      </Section>
      {/* { percent == 100 && <Label>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'SkiftVinkel')[0]?.Label}</Label>} */}
      <Section {...props} clr={clr} isMobile={isMobile} onClick={() => click(+1)} isDetail={isDetail} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        <Arrow {...props} D={'R'} w={ isMobile ? '25' : '25' } fill={ pin==0 && type==="poly" && isIsometry ? isMobile ? clr.primary_text : "#000" : clr?.primary_text }  {...{percent}}/>
      </Section>
    </Main>
  )
}


export const Section = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid ${({ clr, pin, isMobile, isIsometry, type ,isDetail}) =>
    pin == 0 && type === "poly" && isIsometry
      ? isMobile
        ? `${clr?.primary}`
        : `#fff`
      : `${clr?.primary}`};
  background: ${({ clr, pin, isMobile, isIsometry, type, isDetail }) =>
   pin == 0 && type === "poly" && isIsometry ? (isMobile ? `${clr?.primary}bf` : `#ffffffbf`) : `${clr?.primary}bf`};
  justify-content: center;
  align-items: center;
  gap: 10px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
`;