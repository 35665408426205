import { useEffect } from 'react'
import { useScalePoint } from 'hooks/useScalePoint' 
import { useBreak } from 'hooks/useBreak'

export const createZppSetup = (bounds, ref, dimensions, setScale, mode, pin) => {
console.log(pin,"slmlms")
  const isMobile = useBreak('md_dn')
  // const scale = useScalePoint(bounds, dimensions, mode) + (isMobile ? (pin > 0 ? 0.4 : 0.34) : (pin > 0 ? 0.19 : 0.3))
  const scale = useScalePoint(bounds, dimensions, mode) + (isMobile ? (pin > 0 ? 0.34 : 0.34) : (pin == 0 ? 0.25 : pin == 1 ? 0.09 : 0.16))

  useEffect(() => {
    ref.current?.zoomToElement('center', scale, 0, null)
    // ref.current?.setTransform(-dimensions.w/2,0, scale, 0, null)
    setScale(scale ? scale : 1)
  }, [scale, bounds, mode])

  const wrapperProps = {
    wheel: { step: 0.04 },
    limitToBounds: true,
    panning: { velocityDisabled: true },
    alignmentAnimation: { sizeX: 0, sizeY: 0 },
    zoomAnimation: { size: 0 },
    minScale: scale ?? 0,
    maxScale: scale ? 8 : 0,
    onInit: event => event.zoomToElement('center', scale, 0, null),
    onZoom: event => setScale(event.state.scale),
  }

  const wrapperStyle = {
    width:  '100%' ,
    height:  '100%' ,
    position: 'absolute',
  }

  const contentStyle = {
    width: dimensions.W,
    height: dimensions.H,
  }

  return {
    wrapperProps,
    wrapperStyle,
    contentStyle,
  }
}
